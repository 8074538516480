import scroll from 'core/scroll'
// import resize from 'helpers/resize'
import detect from 'helpers/detect'
import Menu from '../menu/Menu'

export default class Header {
  constructor (el) {
    this.el = el
    this.menu = new Menu(el.parentNode.querySelector('.menu'))

    scroll.instance().on(this.onScroll)
    this.button = this.el.querySelector('.header__toggle-menu')
    this.button && this.button.addEventListener('click', this.toggle)
    this.scroll = [...this.el.querySelectorAll('[data-scroll]')]
    this.scroll && this.scroll.forEach(s => s.addEventListener('click', this.scrollTo))

    // this.toggle()
  }

  barVisible = false;

  scrollTo = (event) => {
    const { currentTarget } = event
    event.preventDefault()
    const id = currentTarget.getAttribute('href')
    const target = document.body.querySelector(id)
    // scroll.scrollTo(target.offsetTop, 600)
    scroll.scrollTo(target.offsetTop - this.el.offsetHeight, 600)
  }

  toggle = () => {
    if (this.menu.opened) this.menu.hide()
    else this.menu.show()
    this.el.classList.toggle('menu-opened', this.menu.opened)
  };

  onScroll = () => {
    const { scrollTop } = scroll
    const needBarVisible = scrollTop() > (detect.desktop ? 200 : 100)

    if (needBarVisible !== this.barVisible) {
      document.body.classList.toggle('bar-visible', needBarVisible)
      this.barVisible = needBarVisible
    }
  };

  flush () {
    this.scroll && this.scroll.forEach(s => s.removeEventListener('click', this.scrollTo))
    this.scroll = null

    this.button && this.button.removeEventListener('click', this.toggle)
    this.button = null
    this.menu.flush()
    this.menu = null
    scroll.instance().off(this.onScroll)
  }

  resize () {}
}
