import reverse from 'lodash/reverse'
import anime from 'animejs'

export default class Menu {
  constructor (el) {
    this.el = el
    this.background = el.querySelector('.menu__background')
    this.links = Array.from(el.querySelectorAll('.menu__logo, .menu__link'))
    this.opened = false
  }

  show (pageName) {
    this.opened = true

    const timeline = anime.timeline({
      duration: 400,
      delay: (el, i) => i * 40,
      easing: 'easeOutQuad'
    })

    timeline.add(
      {
        translateY: ['-100%', 0],
        targets: this.background
      },
      0
    )

    timeline.add(
      {
        targets: reverse(this.links.slice(0)),
        translateY: [-100, 0],
        opacity: {
          value: [0, 1],
          easing: 'linear'
        }
      },
      100
    )

    this.el.style.display = 'block'
    return timeline.finished
  }

  hide () {
    this.opened = false

    const timeline = anime.timeline({
      duration: 300,
      easing: 'easeInQuad',
      // delay: (el, i, l) => (l - i) * 50
      delay: (el, i, l) => i * 40
    })

    timeline.add(
      {
        translateY: [0, -100],
        opacity: {
          value: [1, 0],
          easing: 'linear'
        },
        targets: this.links
      },
      0
    )

    timeline.add(
      {
        translateY: [0, '-100%'],
        targets: this.background
      },
      400
    )

    return timeline.finished.then(() => {
      this.el.style.display = 'none'
    })
  }

  flush () {}

  resize () {}
}
